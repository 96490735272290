import {Fragment, useId} from 'react';
import { Accordion, AccordionItem } from '@common/ui/accordion/accordion';

interface Props {
  items: any;
}

export function DetailInfo({items}: Props) {

  const keys = Object.keys(items);

  if (items) {
    return (
      <Fragment>

        <div className="flex flex-wrap">
          <div className='w-full'>

          {keys.map((key: any) => {
            const val = items[key];
            //if ( document.document_type == '')

            if (val != null) {
              if (typeof val === 'string' || val instanceof String || typeof val === 'number') {
                return (
                  <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
                    <div className="flex items-center col-span-3 text-xs">
                      {capitalizeFirstLetter(key.toString())}:
                    </div>
                    <div className="flex items-center font-bold text-sm col-span-12">
                      {val}
                    </div>
                  </div>
                );
              } else {
                const child_keys = Object.keys(val);
                if (val[0] !== undefined && child_keys.length > 1 && Object.keys(val[0]).length == Object.keys(val[1]).length) {
                  return (<>
                    <GridItemInfo label={key} keys={child_keys} items={val}/>
                  </>);
                } else {
                  if (child_keys.length == 1 && val[0] !== undefined) {
                    return (
                      <DetailItemInfo label={key} keys={child_keys} items={val}/>
                    )
                  } else {
                    return (<>
                      <ItemInfo label={key} keys={child_keys} items={val}/>
                    </>);
                  }
                }
              }
            }
          })}
          </div>
        </div>
      </Fragment>
    );
  }
}

function DetailItemInfo({label, keys, items}: {label: string|number, keys: any, items:any}) {
  const headers = Object.keys(items[0]);
  return (
    <>
    {headers.map((header: string, index) => {
      const val = items[0][header];
      if (val != null) {
        if (typeof val === 'string' || val instanceof String || typeof val === 'number') {
          return (
          <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
            <div className="flex items-center col-span-3 text-xs">
              {capitalizeFirstLetter(header.toString())}:
            </div>
            <div className="flex items-center font-bold text-sm col-span-3">
              {val}
            </div>
          </div>
          );
        } else {
          const child_keys = Object.keys(val);
          const key = header;
          if (val[0] !== undefined && child_keys.length > 1 && Object.keys(val[0]).length == Object.keys(val[1]).length) {
            return (<>
              <GridItemInfo label={key} keys={child_keys} items={val}/>
            </>);
          } else {
            if (child_keys.length == 1 && val[0] !== undefined) {
              return (
                <DetailItemInfo label={key} keys={child_keys} items={val}/>
              )
            } else {
              return (<>
                <ItemInfo label={key} keys={child_keys} items={val}/>
              </>);
            }
          }
        }
      }
    })}
    </>
  );
}

function GridItemInfo({label, keys, items}: {label: string|number, keys: any, items:any}) {
  const headers = Object.keys(items[0]);
  const keys2 = Object.keys(items);
  return (
    <>
      <h3 className='pt-20'><strong>{capitalizeFirstLetter(label.toString())}</strong></h3>
      <div className="table-wrp block flex flex-col overflow-y-scroll">
        <table className="w-full file">
          <thead className="bg-white border-b sticky top-0 bg-slate-300">
            <tr className='bg-gray-300'>
              {headers.map((header: string, index) => (
              <th scope="col" className={index == 0 ? "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 pl-20 text-left": "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left"}>
                {capitalizeFirstLetter(header.toString())}
              </th>
              ))}
            </tr>
          </thead>
          <tbody className="h-96 overflow-y-auto">
          {keys.map((key: any) => (
            <tr key={key} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
              {headers.map((header: string, index) => {
                const val = items[key][header];
                if (val != null) {
                  if (typeof val === 'string' || val instanceof String || typeof val === 'number') {
                    return (
                      <td className={index==0? "text-sm font-extrabold text-gray-900 font-light px-6 py-4 pl-20 whitespace-nowrap":"text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"}>
                        {val}
                      </td>
                    );
                  } else {
                    const child_keys = Object.keys(val);
                    const key = header;
                    if (val[0] !== undefined && child_keys.length > 1 && Object.keys(val[0]).length == Object.keys(val[1]).length) {
                      return (<>
                        <GridItemInfo label={key} keys={child_keys} items={val}/>
                      </>);
                    } else {
                      if (child_keys.length == 1 && val[0] !== undefined) {
                        return (
                          <DetailItemInfo label={key} keys={child_keys} items={val}/>
                        )
                      } else {
                        return (<>
                          <ItemInfo label={key} keys={child_keys} items={val}/>
                        </>);
                      }
                    }
                  }
                }
              })}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    
    </>
  );
}
function ItemInfo({label, keys, items}: {label: string|number, keys: any, items:any}) {
  return (
    <>
      <h3 className='pt-20'><strong>{capitalizeFirstLetter(label.toString())}</strong></h3>
      <div className="table-wrp block flex flex-col overflow-y-scroll">
        {keys.map((key:any) => {
          if (items[key]) {
            if (typeof items[key] === 'string' || items[key] instanceof String || typeof items[key] === 'number') {
              return (
                <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
                  <div className="flex items-center col-span-3 text-xs">
                    {capitalizeFirstLetter(key.toString())}:
                  </div>
                  <div className="flex items-center font-bold text-sm col-span-9">
                    {items[key]}
                  </div>
                </div>
              );
            } else {
              const val2 = items[key];
              const child2_keys = Object.keys(val2);
              if (val2[0] !== undefined && child2_keys.length > 1 && Object.keys(val2[0]).length == Object.keys(val2[1]).length) {
                return (<>
                  <GridItemInfo label={key} keys={child2_keys} items={val2}/>
                </>);
              } else {
                if (child2_keys.length == 1 && val2[0] !== undefined) {
                  return (
                    <DetailItemInfo label={key} keys={child2_keys} items={val2}/>
                  )
                } else {
                  return (
                    <ItemInfo label={key} keys={child2_keys} items={items[key]}/>
                  );
                }
              }
            }
          }
        })}
      </div>
    </>
  );
}

function ListItemInfo({label, keys, items}: {label: string|number, keys: any, items:any}) {
  return (
    <>
    <Accordion className="mt-16 col-span-4" variant="outline"
      defaultExpandedValues={[0]}
    >
      <AccordionItem
        value={label}
        key={label}
        label={formatHeader(label)}
        chevronPosition="left"
        buttonPadding="py-10 pl-14 pr-10"
      >
        {keys.map((key:any) => {
          if (items[key]) {
            if (typeof items[key] === 'string' || items[key] instanceof String || typeof items[key] === 'number') {
              return (
                <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
                  <div className="flex items-center col-span-3 text-xs">
                    {capitalizeFirstLetter(key.toString())}:
                  </div>
                  <div className="flex items-center font-bold text-sm col-span-9">
                    {items[key]}
                  </div>
                </div>
              );
            } else {
              const val2 = items[key];
              const child2_keys = Object.keys(val2);
              if (val2[0] !== undefined && child2_keys.length > 1 && Object.keys(val2[0]).length == Object.keys(val2[1]).length) {
                return (<>
                  <GridItemInfo label={key} keys={child2_keys} items={val2}/>
                </>);
              } else {
                if (child2_keys.length == 1 && val2[0] !== undefined) {
                  return (
                    <DetailItemInfo label={key} keys={child2_keys} items={val2}/>
                  )
                } else {
                  return (
                    <ItemInfo label={key} keys={child2_keys} items={items[key]}/>
                  );
                }
              }
            }
          }
        })}
        </AccordionItem>
      </Accordion>
    </>
  );
}
function formatHeader(s: any) {
  if (typeof s === 'number') {
    return '#'+s+". Item";
  }
  return s.replaceAll("_", " ").toUpperCase();
}
function capitalizeFirstLetter(s: string) {
  return s.replaceAll("_", " ").toUpperCase();
}
