import React, {Fragment, useEffect, useState} from 'react';
import {DataTablePage} from '@common/datatable/page/data-table-page';
import {IconButton} from '@common/ui/buttons/icon-button';
import {EditIcon} from '@common/icons/material/Edit';
import {ColumnConfig} from '@common/datatable/column-config';
import {Trans} from '@common/i18n/trans';
import {DataTableEmptyStateMessage} from '@common/datatable/page/data-table-emty-state-message';
import softwareEngineerSvg from '@common/admin/tags/software-engineer.svg';
import {DataTableAddItemButton} from '@common/datatable/data-table-add-item-button';
import {CheckPoint} from './check-point';
import {Link} from 'react-router-dom';
import {Tooltip} from '@common/ui/tooltip/tooltip';
import {DeleteIcon} from '@common/icons/material/Delete';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import { VisibilityIcon } from '@common/icons/material/Visibility';
import moment from 'moment-timezone';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { queryClient } from '@common/http/query-client';
import { DatatableDataQueryKey } from '@common/datatable/requests/paginated-resources';
import { Lender } from '@app_modules/lenders/lender';
import { useDeleteCheckPoint } from '@app_modules/requests/use-delete-check-point';

export function CheckPointsIndexPage() {
  const navigate = useNavigate();
  const timezone = useUserTimezone();
  const [id, setId] = useState(0);
  const [fileId, setFileId] = useState(0);
  const [status, setStatus] = useState('');
  
  // window.Echo.channel('template').listen('.process', (e: any) => {
  //   if (e.data.id) {
  //     setId(e.data.id);
  //   }
  //   if (e.data.fileId) {
  //     setFileId(e.data.fileId);
  //   }
  //   if (e.data.status) {
  //     setStatus(e.data.status);
  //   }
  // });
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: DatatableDataQueryKey('check-points'),
    });
    queryClient.invalidateQueries({queryKey: DatatableDataQueryKey(`check-points/${id}`)});
  }, [id, fileId, status]);

  const columnConfig: ColumnConfig<CheckPoint>[] = [
    {
      key: 'id',
      allowsSorting: false,
      maxWidth: 'max-w-50',
      header: () => <Trans message="ID #" />,
      body:  checkPoint => checkPoint.id,
    },
    {
      key: 'name',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Check Point Name" />,
      body:  checkPoint => checkPoint.name,
    },
    {
      key: 'lender_name',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Lenders" />,
      body:  checkPoint => {
        const items = JSON.parse(checkPoint.lender_ids);
        return (
          <div>
          {items?.map((lender: Lender) => (
            <span>{lender.name}<br/></span>
          ))}
          </div>);
      }
    },
    {
      key: 'updated_by',
      allowsSorting: false,
      header: () => <Trans message="Modified By" />,
      body: lender => lender.updated_by
      
    },
    {
      key: 'updated_at',
      allowsSorting: false,
      maxWidth: 'max-w-140',
      header: () => <Trans message="Last Modify Date" />,
      body: lender => lender.updated_at ? moment.utc(lender.updated_at).tz(timezone).format('MM/DD/YYYY HH:mm:ss') :''
      
    },
    {
      key: 'actions',
      header: () => <Trans message="Actions" />,
      visibleInMode: 'all',
      hideHeader: true,
      align: 'end',
      maxWidth: 'max-w-130',
      body:  checkPoint => {
        return (
          <Fragment>


            {/* <DialogTrigger type="modal">
              <Tooltip label={<Trans message="Edit template" />}>
                <IconButton className="text-muted">
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <UpdateCheckPointDialog checkPoint={checkPoint} />
            </DialogTrigger> */}
            <IconButton
              size="md"
              className="text-muted"
              elementType={Link}
              to={`/check-points/${ checkPoint.id}/edit`}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="md"
              className="text-muted"
              elementType={Link}
              to={`/check-points/${ checkPoint.id}/view`}
            >
              <VisibilityIcon />
            </IconButton>
            
            <DeleteCheckPointButton  checkPoint={ checkPoint} />
          </Fragment>
        );
        
      },
    },
  ];

  return (
    <DataTablePage
      endpoint="check-points"
      title={<Trans message="Check Points" />}
      columns={columnConfig}
      actions={<Actions />}
      enableSelection={false}
      //filters={CheckPointsIndexPageFilters}
      // onRowAction={item => {
      //   navigate(`/reports/${item.report_id}/view`);
      // }}
      emptyStateMessage={
        <DataTableEmptyStateMessage
          image={softwareEngineerSvg}
          title={<Trans message="No Check Points have been created yet" />}
          filteringTitle={<Trans message="No matching Check Points" />}
        />
      }
    />
  );
}

interface DeleteCheckPointButtonProps {
  checkPoint: CheckPoint;
}
function DeleteCheckPointButton({ checkPoint}: DeleteCheckPointButtonProps) {
  const deleteCheckPoint = useDeleteCheckPoint();
  return (
    <DialogTrigger
      type="modal"
      onClose={confirmed => {
        if (confirmed) {
          deleteCheckPoint.mutate({ id:  checkPoint.id});
        }
      }}
    >
      <Tooltip label={<Trans message="Delete Check Point" />}>
        <IconButton
          size="md"
          className="text-muted"
          disabled={deleteCheckPoint.isPending}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title={<Trans message="Delete Check Point" />}
        body={<Trans message="Are you sure you want to delete this Check Point?" />}
        confirm={<Trans message="Delete" />}
      />
    </DialogTrigger>
  );
}


function Actions() {
  return (
    // <DialogTrigger type="modal">
    //   <DataTableAddItemButton>
    //     <Trans message="New Check Point" />
    //   </DataTableAddItemButton>
    //   <CreateCheckPointDialog />
    // </DialogTrigger>
    <Fragment>
      <DataTableAddItemButton elementType={Link} to="/check-points/new">
        <Trans message="New Check Point" />
      </DataTableAddItemButton>
    </Fragment>
  );
}