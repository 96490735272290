import {useForm} from 'react-hook-form';
import {Trans} from '@common/i18n/trans';
import { CrupdateCheckPointLayout } from './crupdate-check-point-layout';
import { CrupdateCheckPointForm } from './crupdate-check-point-form';
import { useGetCheckPointSettings } from '@app_modules/requests/use-check-point-settings';
import { CheckListPayload, useCreateCheckList } from '@app_modules/requests/use-create-check-point';
import { ProgressCircle } from '@common/ui/progress/progress-circle';

export function CreateCheckPointPage() {
  const form = useForm<CheckListPayload>();

  const createCheckPoint = useCreateCheckList(form);
  
  const {data: check_point_setting} = useGetCheckPointSettings();
  
  return (
    <CrupdateCheckPointLayout
      form={form}
      onSubmit={values => {
        createCheckPoint.mutate(values);
      }}
      title={<Trans message="New Check Point" />}
      isLoading={createCheckPoint.isPending}
    >
      
      <CreateCheckPoint data={check_point_setting}/>
    </CrupdateCheckPointLayout>
  );
}


export function CreateCheckPoint({data}:{data: any}) {

  if (data) {
    return (
      <CrupdateCheckPointForm setting={data}/>
    );
  } else {
    return (<ProgressCircle isIndeterminate aria-label="Loading settings..." />);
  }
}
