import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import {useParams} from 'react-router-dom';
import { LoanProduct } from '@app_modules/loan-products/loan-product';
//import {LoanProduct} from '../../loan-products/loan-product';

const Endpoint = (id: number | string) => `loan-products/${id}`;

export interface FetchRoleResponse extends BackendResponse {
   loan_product: LoanProduct;
}

export function useLoanProduct() {
  const { loanProductId} = useParams();
  return useQuery({
    queryKey: [Endpoint( loanProductId!)],
    queryFn: () => fetchLoanProduct( loanProductId!),
  });
}

function fetchLoanProduct( loanProductId: number | string): Promise<FetchRoleResponse> {
  return apiClient.get(Endpoint( loanProductId)).then(response => response.data);
}
