import React, {Fragment} from 'react';
import {DataTablePage} from '@common/datatable/page/data-table-page';
import {IconButton} from '@common/ui/buttons/icon-button';
import {EditIcon} from '@common/icons/material/Edit';
import {FormattedDate} from '@common/i18n/formatted-date';
import {ColumnConfig} from '@common/datatable/column-config';
import {Trans} from '@common/i18n/trans';
import {DataTableEmptyStateMessage} from '@common/datatable/page/data-table-emty-state-message';
import softwareEngineerSvg from '@common/admin/tags/software-engineer.svg';
import {DataTableAddItemButton} from '@common/datatable/data-table-add-item-button';
import {Lender} from './lender';
import {Link} from 'react-router-dom';
import {Tooltip} from '@common/ui/tooltip/tooltip';
import {useDeleteLender} from '@app_modules/admin/lenders/requests/use-delete-lender';
import {DeleteIcon} from '@common/icons/material/Delete';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {LendersIndexPageFilters} from './lenders-index-page-filters';
import { VisibilityIcon } from '@common/icons/material/Visibility';
import moment from 'moment-timezone';
import { useUserTimezone } from '@common/i18n/use-user-timezone';

export function LendersIndexPage() {
  const navigate = useNavigate();
  const timezone = useUserTimezone();

  const columnConfig: ColumnConfig<Lender>[] = [
    {
      key: 'id',
      allowsSorting: false,
      maxWidth: 'max-w-100',
      header: () => <Trans message="#" />,
      body:  lender => lender.id,
    },
    {
      key: 'name',
      allowsSorting: false,
      header: () => <Trans message="Lender Name" />,
      body: lender => lender.name
      
    },

    {
      key: 'updated_by',
      allowsSorting: false,
      header: () => <Trans message="Modified By" />,
      body: lender => lender.updated_by
      
    },
    {
      key: 'updated_at',
      allowsSorting: false,
      maxWidth: 'max-w-140',
      header: () => <Trans message="Last Modify Date" />,
      body: lender => lender.updated_at ? moment.utc(lender.updated_at).tz(timezone).format('MM/DD/YYYY HH:mm:ss') :''
      
    },
    {
      key: 'actions',
      header: () => <Trans message="Actions" />,
      visibleInMode: 'all',
      hideHeader: true,
      align: 'end',
      maxWidth: 'max-w-94',
      body:  lender => {
        return (
          <Fragment>
            <IconButton
              size="md"
              className="text-muted"
              elementType={Link}
              to={`/lenders/${ lender.id}/view`}
            >
              <VisibilityIcon />
            </IconButton>
            

            <IconButton
              size="md"
              className="text-muted"
              elementType={Link}
              to={`/lenders/${ lender.id}/edit`}
            >
              <EditIcon />
            </IconButton>

            <DeleteLenderButton  lender={ lender} />
          </Fragment>
        );
      },
    },
  ];

  return (
    <DataTablePage
      endpoint="lenders"
      title={<Trans message="Lenders" />}
      columns={columnConfig}
      actions={<Actions />}
      enableSelection={false}
      //filters={LendersIndexPageFilters}
      onRowAction={item => {
        navigate(`/lenders/${item.id}/edit`);
      }}
      emptyStateMessage={
        <DataTableEmptyStateMessage
          image={softwareEngineerSvg}
          title={<Trans message="No lenders have been created yet" />}
          filteringTitle={<Trans message="No matching lenders" />}
        />
      }
    />
  );
}

interface DeleteLenderButtonProps {
   lender: Lender;
}
function DeleteLenderButton({ lender}: DeleteLenderButtonProps) {
  const deleteLender = useDeleteLender();
  return (
    <DialogTrigger
      type="modal"
      onClose={confirmed => {
        if (confirmed) {
          deleteLender.mutate({ lenderId:  lender.id});
        }
      }}
    >
      <Tooltip label={<Trans message="Delete lender" />}>
        <IconButton
          size="md"
          className="text-muted"
          disabled={deleteLender.isPending}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title={<Trans message="Delete lender" />}
        body={<Trans message="Are you sure you want to delete this lender?" />}
        confirm={<Trans message="Delete" />}
      />
    </DialogTrigger>
  );
}

function Actions() {
  return (
    <Fragment>
      <DataTableAddItemButton elementType={Link} to="/lenders/new">
        <Trans message="Add new lender" />
      </DataTableAddItemButton>
    </Fragment>
  );
}
