import {useMutation} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {useDataTable} from '@common/datatable/page/data-table-context';
import {message} from '@common/i18n/message';
import {DatatableDataQueryKey} from '@common/datatable/requests/paginated-resources';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';

const endpoint = (id: number|string) => `check-points/${id}`;

interface Response extends BackendResponse {}

interface Payload {
  id: number|string;
}

export function useDeleteCheckPoint() {
  const {trans} = useTrans();
  const {endpoint, selectedRows, setSelectedRows} = useDataTable();
  return useMutation({
    mutationFn: (payload: Payload) => deleteCheckPoint(payload),
    onSuccess: async () => {
      toast(trans(message('Check Point deleted')));
      await queryClient.invalidateQueries({
        queryKey: DatatableDataQueryKey(endpoint),
      });
    },
    onError: err => showHttpErrorToast(err),
  });
}

function deleteCheckPoint({ id }: Payload): Promise<Response> {
  return apiClient.delete(endpoint( id )).then(r => r.data);
}
