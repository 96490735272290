import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import {useParams} from 'react-router-dom';
import {Lender} from '../../../lenders/lender';

export interface FetchRoleResponse extends BackendResponse {
   lenders: Lender[];
}

export function useMyLenders() {
  return useQuery({
    queryKey: ['my-lenders'],
    queryFn: () => fetchLenders(),
  });
}

function fetchLenders(): Promise<FetchRoleResponse> {
  return apiClient.get('my-lenders').then(response => response.data);
}
