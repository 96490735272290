import {Lender} from '../../../lenders/lender';
import {useTrans} from '@common/i18n/use-trans';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {useMutation} from '@tanstack/react-query';
import {toast} from '@common/ui/toast/toast';
import {message} from '@common/i18n/message';
import {apiClient, queryClient} from '@common/http/query-client';
import {DatatableDataQueryKey} from '@common/datatable/requests/paginated-resources';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {UseFormReturn} from 'react-hook-form';

const endpoint = 'lenders';

export function useCreateLender(form: UseFormReturn<Lender>, callback_url: string) {
  const {trans} = useTrans();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (payload: Lender) => createLender(payload),
    onSuccess: () => {
      toast(trans(message('Lender created')));
      queryClient.invalidateQueries({queryKey: [endpoint]});
      queryClient.invalidateQueries({queryKey: ['my-lenders']});
      queryClient.invalidateQueries({
        queryKey: DatatableDataQueryKey('lenders'),
      });
      navigate(callback_url ? callback_url : '/admin/lenders');
    },
    onError: err => onFormQueryError(err, form),
  });
}

function createLender(payload: Lender): Promise<Response> {
  const backendPayload = {
    ...payload
  };
  return apiClient.post(endpoint, backendPayload).then(r => r.data);
}
