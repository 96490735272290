import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode, useCallback, useEffect, useState} from 'react';
import { UWCheckPointsPage } from './uw-check-point-page';
import { CheckPoint } from './check-point';
import { apiClient, queryClient } from '@common/http/query-client';
import { DatatableDataQueryKey } from '@common/datatable/requests/paginated-resources';

interface DetailPanelProps {
  check_point: CheckPoint;
}
export function CheckPointDetail({check_point}: DetailPanelProps) {

  const fetchCheckPoint =  useCallback(async (checkPointId: string) => {
    return await apiClient.get(`check-points/${checkPointId}/view`);
  }, []);

  return (
    <Fragment>
      <UWCheckPointsPage check_point={check_point} fetchCheckPoint={fetchCheckPoint}/>
    </Fragment>
  );
}


