import {Outlet, RouteObject, useRoutes} from 'react-router-dom';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import { CheckPointPage, NewCheckPointPage, UpdateCheckPointPage, ViewCheckPointPage } from './check-points-page';
import { AuthRoute } from '@common/auth/guards/auth-route';

export default function CheckPointRoutes() {
  const DashboardRouteConfig: RouteObject[] = [
    {
      path: '',
      element: (
        <ActiveWorkspaceProvider>
          <Outlet />
        </ActiveWorkspaceProvider>
      ),
      children: [
        {
          path: '/',
          element: (
            <AuthRoute permission="check_points.view">
              <CheckPointPage />
            </AuthRoute>
          ),
        },

        {
          path: 'new',
          element: (
            <AuthRoute permission="check_points.create">
              <NewCheckPointPage/>
            </AuthRoute>
          ),
        },
        
        
        {
          path: ':checkPointId/edit',
          element: (
            <AuthRoute permission="check_points.update">
              <UpdateCheckPointPage/>
            </AuthRoute>
          ),
        },
        
        {
          path: ':checkPointId/view',
          element: (
            <AuthRoute permission="check_points.view">
              <ViewCheckPointPage />
            </AuthRoute>
          ),
        },

        {path: '*', element: <NotFoundPage />},
      ],
    },
  ];

  return useRoutes(DashboardRouteConfig);
}
