import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {Trans} from '@common/i18n/trans';
import {useForm} from 'react-hook-form';
import {CrupdateResourceLayout} from '@common/admin/crupdate-resource-layout';
import {useLender} from '../requests/use-lender';
import {Lender} from '../../../lenders/lender';
import {CrupdateLenderForm} from './crupdate-lender-form';
import {
  UpdateLenderPayload,
  useUpdateLender,
} from '../requests/use-update-lender';
import moment from 'moment-timezone';
import { CrupdateLenderLayout } from './crupdate-lender-layout';

interface CreateLenderProps {
  callback_url: string;
}

export function EditLenderPage({callback_url}: CreateLenderProps) {
  const query = useLender();

  if (query.status !== 'success') {
    return <FullPageLoader />;
  }

  return <PageContent  lender={query.data. lender} callback_url={callback_url}/>;
}

interface PageContentProps {
   lender: Lender;
   callback_url: string;
}
function PageContent({ lender, callback_url}: PageContentProps) {
  const form = useForm<UpdateLenderPayload>({
    defaultValues: {
      ... lender,
    },
  });
  const updateLender = useUpdateLender(form, callback_url);

  return (
    <CrupdateLenderLayout
      form={form}
      onSubmit={values => {
        updateLender.mutate(values);
      }}
      title={
        <Trans message="Edit :name lender" values={{name:  (lender.name)}} />
      }
      isLoading={updateLender.isPending}
    >
      <CrupdateLenderForm />
    </CrupdateLenderLayout>
  );
}
