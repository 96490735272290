import {useSettings} from '@common/core/settings/use-settings';
import React, {Fragment} from 'react';
import {useAuth} from '@common/auth/use-auth';
import {ToastContainer} from '@common/ui/toast/toast-container';
import {AppearanceListener} from '@common/admin/appearance/commands/appearance-listener';
import {CookieNotice} from '@common/ui/cookie-notice/cookie-notice';
import {AuthRoute} from '@common/auth/guards/auth-route';
import {DynamicHomepage} from '@common/ui/dynamic-homepage';
import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {AuthRoutes} from '@common/auth/auth-routes';
import {BillingRoutes} from '@common/billing/billing-routes';
import {NotificationRoutes} from '@common/notifications/notification-routes';
import {ContactUsPage} from '@common/contact/contact-us-page';
import {CustomPageLayout} from '@common/custom-page/custom-page-layout';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {DialogStoreOutlet} from '@common/ui/overlays/store/dialog-store-outlet';
import {EmailVerificationPage} from '@common/auth/ui/email-verification-page/email-verification-page';
import {Route, Routes} from 'react-router-dom';
import {LandingPage} from '@app/landing/landing-page';
import {GuestRoute} from '@common/auth/guards/guest-route';
import BillingTransactionRoutes from '@app_modules/billing-transactions/billing-transaction-routes';
import BillingsRoutes from '@app_modules/billings/billings-routes';
import TeamsRoutes from '@app_modules/teams/teams-routes';
import LoanProductRoutes from '@app_modules/loan-products/loan-product-routes';
import LenderRoutes from '@app_modules/lenders/lender-routes';
import CheckPointRoutes from '@app_modules/check-points/check-point-routes';

const DashboardRoutes = React.lazy(
  () => import('./dashboard/dashboard-routes'),
);

const TransactionRequestRoutes = React.lazy(
  () => import('@app_modules/transaction-requests/transaction-request-routes'),
);

const DocumentRequestRoutes = React.lazy(
  () => import('@app_modules/document-requests/document-request-routes'),
);

const LoanApplicationRoutes = React.lazy(
  () => import('@app_modules/loan-applications/loan-application-routes'),
);

const ChatRoutes = React.lazy(
  () => import('@app_modules/chats/chat-routes'),
);

const ReportRoutes = React.lazy(
  () => import('@app_modules/reports/report-routes'),
);

const TransactionRoutes = React.lazy(
  () => import('@app_modules/transactions/transaction-routes'),
);

const EditorPage = React.lazy(() => import('@app/editor/editor-page'));
const AdminRoutes = React.lazy(() => import('@common/admin/admin-routes'));
const SwaggerApiDocs = React.lazy(
  () => import('@common/swagger/swagger-api-docs-page'),
);

export function AppRoutes() {
  const {billing, notifications, require_email_confirmation, api} =
    useSettings();
  const {user, hasPermission} = useAuth();

  if (user != null && require_email_confirmation && !user.email_verified_at) {
    return (
      <Fragment>
        <ToastContainer />
        <Routes>
          <Route path="*" element={<EmailVerificationPage />} />
        </Routes>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <AppearanceListener />
      <CookieNotice />
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            <DynamicHomepage
              homepageResolver={() => (
                <GuestRoute>
                  <LandingPage />
                </GuestRoute>
              )}
            />
          }
        />
        <Route
          path="/admin/*"
          element={
            <AuthRoute permission="admin.access">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <AdminRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />
       <Route
          path="/transaction-requests/*"
          element={
            <AuthRoute permission="transaction_requests.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <TransactionRequestRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/document-requests/*"
          element={
            <AuthRoute permission="document_requests.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <DocumentRequestRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/loan-applications/*"
          element={
            <AuthRoute permission="loan_applications.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <LoanApplicationRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/loan-products/*"
          element={
            <AuthRoute permission="loan_products.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <LoanProductRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/lenders/*"
          element={
            <AuthRoute permission="lenders.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <LenderRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

      <Route
          path="/check-points/*"
          element={
            <AuthRoute permission="check_points.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <CheckPointRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/chats/*"
          element={
            <AuthRoute permission="chats.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <ChatRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/billing-transactions/*"
          element={
            <AuthRoute permission="billing_transactions.view">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <BillingTransactionRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/billings/*"
          element={
            <AuthRoute permission="billings.view">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <BillingsRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/teams/*"
          element={
            <AuthRoute permission="teams.view">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <TeamsRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/reports/*"
          element={
            <AuthRoute permission="reports.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <ReportRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/transactions/*"
          element={
            <AuthRoute permission="transactions.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <TransactionRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/dashboard/*"
          element={
            <AuthRoute permission="document_requests.view">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <DashboardRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/home"
          element={
            <AuthRoute permission="document_requests.view">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <DashboardRoutes />
              </React.Suspense>
            </AuthRoute>
          }
        />

        <Route
          path="/editor/:projectId"
          element={
            <AuthRoute permission="projects.create">
              <React.Suspense fallback={<FullPageLoader screen />}>
                <EditorPage />
              </React.Suspense>
            </AuthRoute>
          }
        />

        {AuthRoutes}
        {billing.enable && BillingRoutes}
        {notifications.integrated && NotificationRoutes}
        {api?.integrated && hasPermission('api.access') && (
          <Route
            path="api-docs"
            element={
              <React.Suspense fallback={<FullPageLoader screen />}>
                <SwaggerApiDocs />
              </React.Suspense>
            }
          />
        )}
        <Route path="contact" element={<ContactUsPage />} />
        <Route path="pages/:pageSlug" element={<CustomPageLayout />} />
        <Route path="pages/:pageId/:pageSlug" element={<CustomPageLayout />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <DialogStoreOutlet />
    </Fragment>
  );
}
