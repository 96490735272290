import {CheckPoint} from './check-points/check-point';
import {
  useInfiniteData,
  UseInfiniteDataProps,
} from '@common/ui/infinite-scroll/use-infinite-data';
import {useActiveWorkspaceId} from '@common/workspace/active-workspace-id-context';

interface Params {
  userId?: string | number;
  published?: string;
  paginate?: UseInfiniteDataProps<undefined>['paginate'];
}

export function useCheckPoints(params: Params) {
  const {workspaceId} = useActiveWorkspaceId();
  return useInfiniteData<CheckPoint>({
    queryKey: ['check-points', {...params, workspaceId}],
    endpoint: 'check-points',
    paginate: params.paginate || 'simple',
    defaultOrderBy: 'updated_at',
    defaultOrderDir: 'desc',
    queryParams: {
      ...params,
      workspaceId,
    },
    willSortOrFilter: true,
  });
}
