import {useLenders} from '../use-lenders';
import {useAuth} from '@common/auth/use-auth';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode, useState} from 'react';
import {useTrans} from '@common/i18n/use-trans';
import {message} from '@common/i18n/message';
import {Footer} from '@common/ui/footer/footer';
import {AnimatePresence, m} from 'framer-motion';
import {DashboardNavbar} from '@app/dashboard/dashboard-navbar';
import {useAccountUsage} from '@app/editor/use-account-usage';
import {AdHost} from '@common/admin/ads/ad-host';
import {StaticPageTitle} from '@common/seo/static-page-title';
import { LendersIndexPage } from './lenders-index-page';
import { CreateLenderPage } from '@app_modules/admin/lenders/crupdate-lender-page/create-lender-page';
import { EditLenderPage } from '@app_modules/admin/lenders/crupdate-lender-page/edit-lender-page';
import { LenderViewPage } from './lender-view-page';

const SortOptions = [
  {value: 'created_at|desc', label: message('Date created')},
  {value: 'name|asc', label: message('Alphabetical')},
  {value: 'updated_at|desc', label: message('Last updated')},
];

export function LenderPage() {
  const {data: usage} = useAccountUsage();
  const {trans} = useTrans();
  const {user} = useAuth();
  const query = useLenders({userId: user!.id, paginate: 'lengthAware'});
  const {sortDescriptor, setSortDescriptor, searchQuery, setSearchQuery} =
    query;
  const sortValue = `${sortDescriptor.orderBy}|${sortDescriptor.orderDir}`;

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Lender" />
      </StaticPageTitle>
      <div className="flex h-screen flex-col overflow-y-scroll">
        <DashboardNavbar />
        <div className='relative'>
          <div className=" mx-auto flex-auto px-12">
            <div className="items-center gap-12 py-361">
              <div className='mr-auto'>
              <AdHost slot="dashboard_top" className="mb-36" />
              <AnimatePresence initial={false} mode="wait">
                <LendersIndexPage/>

              </AnimatePresence>
              <AdHost slot="dashboard_bottom" className="mt-36" />
              </div>
            </div>
          </div>
        </div>
        <Footer className="mt-40 px-40" />
      </div>
    </Fragment>
  );
}

export function NewLenderPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="New Lender" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="container mx-auto mt-40 px-20">
        <CreateLenderPage callback_url="/lenders"/>
        <Footer />
      </div>
    </div>
  );
}


export function UpdateLenderPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="Edit Lender" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="container mx-auto mt-40 px-20">
        <EditLenderPage callback_url="/lenders"/>
        <Footer />
      </div>
    </div>
  );
}

export function ViewLenderPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="View Lender" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="container mx-auto mt-40 px-20">
        <LenderViewPage callback_url="/lenders"/>
        <Footer />
      </div>
    </div>
  );
}




