import {useTrans} from '@common/i18n/use-trans';
import {useContext, useState, useEffect, useCallback, Fragment} from 'react';
import { Trans } from '@common/i18n/trans';
import { useSettings } from '@common/core/settings/use-settings';
import { slugifyString } from '@common/utils/string/slugify-string';
import { Button } from '@common/ui/buttons/button';
import { Dialog } from '@common/ui/overlays/dialog/dialog';
import { DialogHeader } from '@common/ui/overlays/dialog/dialog-header';
import { DialogBody } from '@common/ui/overlays/dialog/dialog-body';
import { DialogFooter } from '@common/ui/overlays/dialog/dialog-footer';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { useForm, useFormContext } from 'react-hook-form';
import {FileWithPath, useDropzone} from 'react-dropzone';
import "react-dropzone/examples/theme.css";
import { CreateDocumentUploadPayload, CreateLoanDocumentPayload } from '@app_modules/requests/use-create-loan-document';
import { Form } from '@common/ui/forms/form';
import { formatBytes } from '@common/utils/number/byte-format';



export function UploadDialog({index, document_type, addFiles}: {index: number, document_type?: string, addFiles: any}) {
  const form = useForm<CreateDocumentUploadPayload>({
    defaultValues: {document_type: document_type},
  });
  const {formId, close} = useDialogContext();
  const documentType = slugifyString(document_type!, "_").replaceAll("-", "_");
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles: any) => {
    addFiles(index, acceptedFiles);
    setFiles(acceptedFiles);
	}, []);


  return (
    <Dialog size='800px'>
      <DialogHeader>
        <Trans message="Upload Documents" /> {document_type}
      </DialogHeader>
      <DialogBody>
        <Form
            id={formId}
            form={form}
            onSubmit={value => {
              // updateProductTemplate.mutate(value, {
              //   onSuccess: () => {
              //     queryClient.invalidateQueries({queryKey: DatatableDataQueryKey('product-templates')});
              //     queryClient.invalidateQueries({queryKey: [`product-templates/${productTemplate.id}`]});
              //     close();
              //   },
              // });
              close();
            }}
          >
            
          {documentType == 'BSA' ? (<>
            <LoanDocumentFile onDrop={onDrop}/>
          </>):(<>
            <LoanDocumentFile onDrop={onDrop}/>
          </>)}
        </Form>
      </DialogBody>
      <DialogFooter>
        {/* <Button variant="text" onClick={close}>
          <Trans message="OK" />
        </Button> */}
        <Button
          variant="flat"
          color="orange"
          type="submit"
          form={formId}
          disabled={!documentType}
        >
          <Trans message="Add Document" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

function LoanDocumentFile({onDrop}: any) {

  const {report: setting_report, uploads} = useSettings();
  const max_file_upload = setting_report ? setting_report.max_file_upload:24;
  const extensions = uploads && uploads.allowed_extensions?.length? uploads.allowed_extensions : ['pdf'];
  const [accepts, setAccepts] = useState<any>({});

  extensions?.forEach((extension:string) => {
    let ext = 'application/'+extension;
    if (extension == 'xml') { 
      ext = 'text/'+extension;
      accepts['application/'+extension] = [];
    } else if (extension == 'html' || extension == 'csv') { 
      ext = 'text/'+extension;
    } else if (extension == 'pdf' || extension == 'json') { 
      ext = 'application/'+extension;
    } else if (extension == 'doc') { 
      ext = 'application/msword';
    } else if (extension == 'docx') { 
      ext = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (extension == 'jpeg' || extension == 'jpg') { 
      ext = 'image/jpeg';
    } else if (extension == 'png') { 
      ext = 'image/x-png';
    }
    accepts[ext] = [];
  });


  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: accepts ? accepts : {
      'application/pdf': [],
    },
    onDrop,
    maxFiles: max_file_upload
  });

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone h-200' })}>
        <input name='files' {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(Only <strong>{extensions?.join(", ")}</strong> will be accepted and {max_file_upload} files are the maximum number of files you can drop here)</em>
      </div >
        <div>
        <h4 className='h-40 t-40'><strong>Uploaded files</strong></h4>
        <div className="table-wrp block flex flex-col overflow-y-scroll">
          <table className="w-full file">
            <thead className="bg-white border-b sticky top-0 bg-slate-300">
              <tr className='bg-gray-300'>
              <th scope="col" className="bg-gray-300 text-sm font-medium text-gray-900 px-6 py-4 pl-20 text-left">
                  File Name
                </th>
                <th scope="col" className="bg-gray-300 text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  Size
                </th>
              </tr>
            </thead>
            <tbody className="h-96 overflow-y-auto">
            {acceptedFiles.map((file: FileWithPath, index) => (
              <tr key={file.path} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td className="text-sm font-extrabold text-gray-900 font-light px-6 py-4 pl-20 whitespace-nowrap">
                {index+1}. {file.path}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{formatBytes(file.size)}</td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
