import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode} from 'react';
import {FormSelect} from '@common/ui/forms/select/select';
import {Item} from '@common/ui/forms/listbox/item';
import { LoanProduct } from './loan-product';
import { FormDatePicker } from '@common/ui/forms/input-field/date/date-picker/date-picker';
import { Button } from '@common/ui/buttons/button';
import { useSettings } from '@common/core/settings/use-settings';
import moment from 'moment-timezone';
import { useUserTimezone } from '@common/i18n/use-user-timezone';

interface DetailPanelProps {
  loanProduct: LoanProduct;
}
export function LoanProductDetail({loanProduct}: DetailPanelProps) {
  const timezone = useUserTimezone();

  return (
    <Fragment>

      <div className="flex flex-wrap border-b-1 pb-20">
        <div className='w-full'>
          <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
              <div className="flex items-center col-span-1 text-xs">
                <Trans message="Account Holder Name"/>:
              </div>
              <div className="flex items-center font-bold text-sm col-span-3">
                {loanProduct.name}
              </div>
              <div className="flex items-center col-span-1 text-xs">
                <Trans message="Order By"/>:
              </div>
              <div className="flex items-center font-bold text-sm col-span-2">
                {loanProduct.order_by}
              </div>
          </div>
          <div className="grid grid-cols-12 md:grid-cols-12 gap-3"> 
              <div className="flex items-center col-span-1 text-xs">
                <Trans message="Create Date"/>:
              </div>
              <div className="flex items-center font-bold text-sm col-span-3">
                {moment.utc(loanProduct.created_at).tz(timezone).format('MM/DD/YYYY')}
              </div>
              <div className="flex items-center col-span-1 text-xs">
                <Trans message="Last Request Date"/>:
              </div>
              
            </div>
          

        </div>
      </div>
    </Fragment>
  );
}

interface HeaderProps {
  children: ReactNode;
}
function Header({children}: HeaderProps) {
  return <h2 className="mt-40 mb-20 text-base font-semibold">{children}</h2>;
}


