import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {Trans} from '@common/i18n/trans';
import { ViewResourceLayout } from './view-resource-layout';
import { CheckPointDetail } from './check-point-detail';
import { useCheckPoint } from '@app_modules/requests/use-check-point';
import { CheckPoint } from './check-point';
import { Fragment } from 'react';
import { DataTableAddItemButton } from '@common/datatable/data-table-add-item-button';
import { Link } from 'react-router-dom';

export function CheckPointViewPage() {
  const query = useCheckPoint();
    

  if (query.status !== 'success') {
    return <FullPageLoader />;
  }

  return <PageContent checkPoint={query.data.check_point}/>;
}

interface PageContentProps {
   checkPoint: CheckPoint;
}
function PageContent({ checkPoint}: PageContentProps) {
  return (
    <ViewResourceLayout
      title={
        <Trans message="Check Point :name" values={{name:  checkPoint.name}} />
      }
    >
      <CheckPointDetail check_point={checkPoint}/>
    </ViewResourceLayout>
  );
  
}