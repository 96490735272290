
import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import {useParams} from 'react-router-dom';
import { CheckPoint } from '@app_modules/check-points/check-point';

const Endpoint = (id: number | string) => `check-points/${id}/find`;

export interface FetchRoleResponse extends BackendResponse {
   check_point: CheckPoint;
}

export function useGetCheckPoint() {
  const { checkPointId} = useParams();
  return useQuery({
    queryKey: [Endpoint( checkPointId!)],
    queryFn: () => fetchCheckPoint( checkPointId!),
  });
}

function fetchCheckPoint( checkPointId: number | string): Promise<FetchRoleResponse> {
  return apiClient.get(Endpoint( checkPointId)).then(response => response.data);
}
