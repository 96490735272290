import {useTrans} from '@common/i18n/use-trans';
import {Fragment} from 'react';
import { Trans } from '@common/i18n/trans';
import { slugifyString } from '@common/utils/string/slugify-string';
import { Button } from '@common/ui/buttons/button';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { Dialog } from '@common/ui/overlays/dialog/dialog';
import { DialogHeader } from '@common/ui/overlays/dialog/dialog-header';
import { DialogBody } from '@common/ui/overlays/dialog/dialog-body';
import { DialogFooter } from '@common/ui/overlays/dialog/dialog-footer';
import { List, ListItem } from '@common/ui/list/list';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { CheckPoint } from './check-point';
import { SubCheckList } from './sub-check-list';
import { DetailInfo } from '@app_modules/loan-applications/step8/detail-info';


export function ResultAction({
  checkPoint,
  subCheckList,
  pindex
}: {subCheckList: SubCheckList, pindex: number, checkPoint: CheckPoint}) {

  const result = checkPoint.check_results;
  const loanCategory = slugifyString(subCheckList.category!, "_").replaceAll("-", "_");
  const documentType = slugifyString(subCheckList.document_type!, "_").replaceAll("-", "_");

  const item = result && result[loanCategory+"-"+documentType] ? result[loanCategory+"-"+documentType] : [];

  if (item) {
    return (
      <div className='flex w-full gap-10'>
       <span className={item.is_ok ? `text-green font-bold mt-6`: 'font-bold text-red mt-6'}> {item.is_ok ? 'PASSED' : 'FAILED'}</span>
      <DialogTrigger type="modal">
        <Button size={'xs'} variant="outline" color="orange">
          <Trans message="View Detail" />
        </Button>

        <ViewDetailDialog key={`detail-${pindex}`} loan_category={subCheckList.category!} document_type={subCheckList.document_type!}  details={item.details ? item.details : (item.result ? item.result : item.detail)} />
      </DialogTrigger>
        
      </div>
    );
  } else {
    return (<></>);
  }
}


export function ViewDetailDialog({loan_category, document_type, details}: {loan_category: string, document_type: string, details: any}) {
  
  const {trans} = useTrans();
  const {close} = useDialogContext();

  return (
    <Dialog size='2xl'>
      <DialogHeader>
        <Trans message={`View Detail ${loan_category} - ${document_type}`} />
      </DialogHeader>
      <DialogBody>
        <Fragment>
          <List padding="p-0" className='scroll-p-8 overflow-y-scroll h-300 min-h-300 bg-gray-300'>
            {details?.map((detail_item: any, index: number) => (
              <DetailItem key={`detail-content-${index}`} detail_item={detail_item}></DetailItem>
            ))}
          </List>
        </Fragment>
      </DialogBody>
      <DialogFooter>
      <Button onClick={() => close()}>
          <Trans message="Close" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export function DetailItem({detail_item}:{detail_item: any}) {
  return (
    <ListItem padding="px-4 py-10" className='whitespace-normal'>
      <div className='w-full'>
         <DetailInfo items={detail_item}></DetailInfo>
      </div>
    </ListItem>
  );
}