import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {Trans} from '@common/i18n/trans';
import { ViewResourceLayout } from './view-resource-layout';
import { useLoanProduct } from '@app_modules/requests/use-loan-product';
import { LoanProduct } from './loan-product';
import { LoanProductDetail } from './loan-product-detail';


interface ViewLoanProductProps {
  callback_url: string;
}

export function LoanProductViewPage({callback_url}: ViewLoanProductProps) {
  const query = useLoanProduct();


  if (query.status !== 'success') {
    return <FullPageLoader />;
  }

  return <PageContent loanProduct={query.data.loan_product}/>;
}

interface PageContentProps {
   loanProduct: LoanProduct;
}
function PageContent({ loanProduct}: PageContentProps) {
    

  return (
    <ViewResourceLayout
      title={
        <Trans message="Loan Product :name" values={{name:  loanProduct.name}} />
      }
      // isLoading={viewLoanProduct.isPending}
    >
      <LoanProductDetail loanProduct={loanProduct}/>

      <div className="bg-gray-50 flex ">
        <div className="w-full mx-auto grid grid-cols-12 bg-gray-200">
          
        </div>
      </div>
    </ViewResourceLayout>
  );
  
}
