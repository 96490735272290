import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import { CheckPointSettings } from '@app_modules/admin/check-points/check-point-settings';

export interface FetchCheckPointSettingsResponse
  extends BackendResponse,
  CheckPointSettings {}


export function useGetCheckPointSettings() {
  return useQuery({
    queryKey: ['getCheckPointSettings'],
    queryFn: () => getCheckPointSettings(),
  });
}

function getCheckPointSettings(): Promise<FetchCheckPointSettingsResponse> {
  return apiClient.get('check-points/settings/check-list').then(response => response.data);
}
