import {useMutation, useQuery} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { showHttpErrorToast } from '@common/utils/http/show-http-error-toast';



const endpoint = (loan_product_id: number | string) => `loan-products/${loan_product_id}/download-md`;

interface Response extends BackendResponse {}

interface Payload {
  loan_product_id: number | string;
  file_name: string;
}

export function useDownloadMdProduct() {

  return useMutation({
    mutationFn: (payload: Payload) => fetchMdProduct(payload),
    onError: err => showHttpErrorToast(err),
  });
}

function fetchMdProduct({loan_product_id, file_name}: Payload): Promise<Response> {
  return apiClient.post(endpoint( loan_product_id), {file_name: file_name}, {responseType: 'blob'}).then(r => r.data);
}
