import {LoanProduct} from './loan-products/loan-product';
import {
  useInfiniteData,
  UseInfiniteDataProps,
} from '@common/ui/infinite-scroll/use-infinite-data';
import {useActiveWorkspaceId} from '@common/workspace/active-workspace-id-context';

interface Params {
  userId?: string | number;
  published?: string;
  paginate?: UseInfiniteDataProps<undefined>['paginate'];
}

export function useLoanProducts(params: Params) {
  const {workspaceId} = useActiveWorkspaceId();
  return useInfiniteData<LoanProduct>({
    queryKey: ['loan-products', {...params, workspaceId}],
    endpoint: 'loan-products',
    paginate: params.paginate || 'simple',
    defaultOrderBy: 'updated_at',
    defaultOrderDir: 'desc',
    queryParams: {
      ...params,
      workspaceId,
    },
    willSortOrFilter: true,
  });
}
