import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode} from 'react';
import {FormSelect} from '@common/ui/forms/select/select';
import {Item} from '@common/ui/forms/listbox/item';
import { FormDatePicker } from '@common/ui/forms/input-field/date/date-picker/date-picker';
import { useSettings } from '@common/core/settings/use-settings';

export function LenderDetail() {
  const {report: setting_report} = useSettings();
  const status_lists = setting_report ? JSON.parse(setting_report.status_lists):[];

  return (
    <Fragment>
      <FormTextField
        name="name"
        label={<Trans message="Name" />}
        className="mb-20"
        required
        autoFocus
      />
      <FormTextField
        name="last_name"
        label={<Trans message="Last Name" />}
        className="mb-20"
        required
        autoFocus
      />
      <FormTextField
        name="bank_name"
        label={<Trans message="Bank Name" />}
        className="mb-20"
        required
      />

      <FormTextField
        name="account_number"
        label={<Trans message="Account Number" />}
        className="mb-20"
        required
      />

      <FormTextField
        name="begin_balance"
        label={<Trans message="Begin Balance" />}
        className="mb-20"
        required
      />

      <FormTextField
        name="ending_balance"
        label={<Trans message="Ending Balance" />}
        className="mb-20"
        required
      />
      <FormDatePicker
        className="mb-20"
        name="date_from"
        label={<Trans message="Lender Date From" />}
      />
      <FormDatePicker
        className="mb-20"
        name="date_to"
        label={<Trans message="Lender Date To" />}
      />
      
      <FormSelect
        name="status"
        selectionMode="single"
        required
        label={<Trans message="Status" />}
        className="mb-20"
      >
        {status_lists?.map((item_status: string) => (
        <Item value={item_status} key={item_status}>
          <Trans message={item_status} />
        </Item>
        ))}
      </FormSelect>
    </Fragment>
  );
}

interface HeaderProps {
  children: ReactNode;
}
function Header({children}: HeaderProps) {
  return <h2 className="mt-40 mb-20 text-base font-semibold">{children}</h2>;
}


