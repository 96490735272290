import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode, useCallback, useEffect, useState} from 'react';
import { List, ListItem } from '@common/ui/list/list';
import { SubCheckList } from './sub-check-list';
import { useTrans } from '@common/i18n/use-trans';
import { ArrowCircleRightIcon } from '@common/icons/material/ArrowCircleRight';
import { FormSwitch } from '@common/ui/forms/toggle/switch';
import { CheckList } from './check-list';
import { useFormContext } from 'react-hook-form';
import { CheckPointPayload } from '@app_modules/admin/check-points/requests/update-check-point-settings';
import { CheckListPayload } from '@app_modules/requests/use-create-check-point';
import { ProgressCircle } from '@common/ui/progress/progress-circle';
import { useMyLenders } from '@app_modules/admin/lenders/requests/use-my-lenders';
import { FormSelect } from '@common/ui/forms/select/select';
import { Item } from '@common/ui/forms/listbox/item';
import { Lender } from '@app_modules/lenders/lender';
import { FormChipField } from '@common/ui/forms/input-field/chip-field/form-chip-field';
import { slugifyString } from '@common/utils/string/slugify-string';

export function CrupdateCheckPointForm({setting, items}:{setting:any, items?: any}) {

  const {watch, setValue} = useFormContext();

  const sub_check_points = items ? items : (setting ? setting.check_points : []);

  const form = useFormContext<CheckListPayload>();
  
  const [checkPoints, setCheckPoints] = useState<CheckList[]>(sub_check_points ? sub_check_points : []);
  useEffect(() => {
    if (sub_check_points.length) {
      setCheckPoints(sub_check_points);
    }
  }, [sub_check_points]);

  const {data: mylenders} = useMyLenders();
  const lenders = mylenders?.lenders;
  const watchedLenderIds = watch('lender_ids');

  const val = watchedLenderIds && watchedLenderIds instanceof Object ? [watchedLenderIds] : (watchedLenderIds ? JSON.parse(watchedLenderIds) : []);
  const setInputValue = (items: any) => {
      setValue('lender_ids', JSON.stringify(items), {
        shouldDirty: true,
      });
  };
  
  const onSubItems = useCallback((pidx: number, subItems: any) => {
    
      checkPoints[pidx]['subItems'] = subItems;
      form.setValue('items', checkPoints);

	}, []);

  return (
    <Fragment>
      {checkPoints.length ? (
      <>
        <FormTextField
          name="name"
          label={<Trans message="Check Point Name" />}
          className="mb-20"
          required
          autoFocus
        />
        <FormChipField
        className="mb-20"
        label={<Trans message="Lenders" />}
        name="lender_ids"
        chipSize="sm"
        value={val}
        onChange={setInputValue}
      >
        {lenders?.map((lender: Lender) => (
          <Item value={lender.name} key={lender.id}>
            <Trans message={lender.name} />
          </Item>
        ))}
      </FormChipField>

        <h3 className='w-[90%] font-bold'>Check Points</h3>

        <aside className="sticky top-10 w-[100%] flex">
        <table className="w-[100%] table">
        <thead className="border-b sticky top-0 bg-slate-300">
          <tr className='bg-gray-300'>
            <th scope="col" className="w-[5%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            </th>
            <th scope="col" className="w-[30%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            <Trans message="Check Point Category" />
            </th>
            <th scope="col" className="w-[50%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            <Trans message="Document Type" />
            </th>
            <th scope="col" className="w-[15%] bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
            <Trans message="Active" />
            </th>
          </tr>
        </thead>
        <tbody className="h-30 overflow-y-auto">
          {checkPoints?.map((item: any, pidx: number) => (
            <SubCheckListMeta key={`check-list-${pidx}`} pidx={pidx} onSubItems={onSubItems} subCheckPoints={item.sub_check_points}/>
          ))}
        </tbody>
      </table>


          
        </aside>
      </>):(<ProgressCircle isIndeterminate aria-label="Loading settings..." />)
    }
    </Fragment>
  );
}

function SubCheckListMeta({onSubItems, pidx, subCheckPoints}: {onSubItems: any, pidx: number, subCheckPoints: SubCheckList[]}) {

  const [subItems, setSubItems] = useState<SubCheckList[]>(subCheckPoints? subCheckPoints : []);

  useEffect(() => {
    setSubItems(subCheckPoints);
    onSubItems(pidx, subCheckPoints);
  }, [subCheckPoints]);

  return (
    <>
      {subItems?.map((subItem: SubCheckList, index: number) => (

        <tr key={`sub-${pidx}-${index}`} className="border-b transition duration-300 ease-in-out hover:bg-gray-100 w-[100%] h-60">
          <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap `}>
            {index == 0 ? (
            <ArrowCircleRightIcon size='lg'/>
            ): (<></>)}
          </td>
          <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r  border-l`}>
            {index == 0 ? (
            <>
              {subItem.category}
            </>
            ): (<></>)}
          </td>
          <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r  border-l`}>
            {subItem.document_type}
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
              <FormSwitch
                name={`${slugifyString(subItem.category!, "_").replaceAll("-", "_")}_${slugifyString(subItem.document_type!, "_").replaceAll("-", "_")}_check`}
                onChange={val => {
                  const is_check = val.target.checked ? true : false;
                  subItems[index]['checked'] = is_check;
                  onSubItems(pidx, subItems);
                }}
              >
              </FormSwitch>
          </td>
        </tr>
        ))}
    </>
  );
}

