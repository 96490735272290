import {useForm} from 'react-hook-form';
import {CrupdateLoanProductLayout} from './crupdate-loan-product-layout';
import {Trans} from '@common/i18n/trans';
import {CrupdateLoanProductForm} from './crupdate-loan-product-form';
import { useCallback, useState } from 'react';
import { apiClient } from '@common/http/query-client';
import { FilePayload, useCreateLoanProduct } from '@app_modules/requests/use-create-loan-product';

export function CreateLoanProductPage() {
  const form = useForm<FilePayload>();

  const createLoanProduct = useCreateLoanProduct(form);
  const [isEnable, setIsEnable] = useState(false);

  const [newLender, setNewLender] = useState(0);

  const fetchTemplate =  useCallback(async (lenderId:string|number) => {
    return await apiClient.get(`lenders/${lenderId}/templates`);
  }, [newLender]);

  return (
    <CrupdateLoanProductLayout
      form={form}
      onSubmit={values => {
        createLoanProduct.mutate(values);
      }}
      title={<Trans message="New Loan Product" />}
      isLoading={createLoanProduct.isPending}
      isEnable={isEnable}
    >
      <CrupdateLoanProductForm isEnable={setIsEnable} fetchTemplate={fetchTemplate}/>
    </CrupdateLoanProductLayout>
  );
}
