import {Outlet, RouteObject, useRoutes} from 'react-router-dom';
import {NotFoundPage} from '@common/ui/not-found-page/not-found-page';
import {ActiveWorkspaceProvider} from '@common/workspace/active-workspace-id-context';
import { AuthRoute } from '@common/auth/guards/auth-route';
import { LenderPage, NewLenderPage, UpdateLenderPage, ViewLenderPage } from './lender-page';

export default function LenderRoutes() {
  const DashboardRouteConfig: RouteObject[] = [
    {
      path: '',
      element: (
        <ActiveWorkspaceProvider>
          <Outlet />
        </ActiveWorkspaceProvider>
      ),
      children: [
        {
          path: '/',
          element: (
            <AuthRoute permission="lenders.view">
              <LenderPage />
            </AuthRoute>
          ),
        },
        
        {
          path: 'new',
          element: (
            <AuthRoute permission="lenders.create">
              <NewLenderPage />
            </AuthRoute>
          ),
        },
        {
          path: ':lenderId/edit',
          element: (
            <AuthRoute permission="lenders.update">
              <UpdateLenderPage />
            </AuthRoute>
          ),
        },
        {
          path: ':lenderId/view',
          element: (
            <AuthRoute permission="lenders.view">
              <ViewLenderPage />
            </AuthRoute>
          ),
        },

        {path: '*', element: <NotFoundPage />},
      ],
    },
  ];

  return useRoutes(DashboardRouteConfig);
}
