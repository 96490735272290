import {useMutation} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {message} from '@common/i18n/message';
import {DatatableDataQueryKey} from '@common/datatable/requests/paginated-resources';
import {Lender} from '../../../lenders/lender';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {UseFormReturn} from 'react-hook-form';
import {onFormQueryError} from '@common/errors/on-form-query-error';

interface Response extends BackendResponse {
   lender: Lender;
}

export interface UpdateLenderPayload extends Lender {
  id: number;
}

const Endpoint = (id: number) => `lenders/${id}`;

export function useUpdateLender(form: UseFormReturn<UpdateLenderPayload>, callback_url: string) {
  const {trans} = useTrans();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (payload: UpdateLenderPayload) => updateLender(payload),
    onSuccess: response => {
      toast(trans(message('Lender updated')));
      queryClient.invalidateQueries({
        queryKey: [Endpoint(response. lender.id)],
      });
      queryClient.invalidateQueries({
        queryKey: DatatableDataQueryKey('lenders'),
      });
      navigate(callback_url ? callback_url: '/admin/lenders');
    },
    onError: err => onFormQueryError(err, form),
  });
}

function updateLender({
  id,
  ...payload
}: UpdateLenderPayload): Promise<Response> {
  const backendPayload = {
    ...payload
  };
  return apiClient.put(Endpoint(id), backendPayload).then(r => r.data);
}
