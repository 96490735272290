import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {Trans} from '@common/i18n/trans';
import {useForm} from 'react-hook-form';
import {useLoanProduct} from '../requests/use-loan-product';
import {CrupdateLoanProductForm} from './crupdate-loan-product-form';
import { useUpdateLoanProduct } from '@app_modules/requests/use-update-loan-product';

import { FilePayload } from '@app_modules/requests/use-create-loan-product';
import { useCallback, useState } from 'react';
import { apiClient } from '@common/http/query-client';
import { CrupdateLoanProductLayout } from './crupdate-loan-product-layout';
import { LoanProduct } from './loan-product';


export function EditLoanProductPage() {
  const query = useLoanProduct();

  if (query.status !== 'success') {
    return <FullPageLoader />;
  }

  return <PageContent loanProduct={query.data.loan_product}/>;
}

interface PageContentProps {
   loanProduct: LoanProduct;
}
function PageContent({ loanProduct}: PageContentProps) {

  const [defaultValues, setDefaultValues] = useState<any>({});

  defaultValues["name"] = loanProduct.name;
  defaultValues["lender_id"] = loanProduct.lender_id;
  if (loanProduct.products) {
    loanProduct.products.forEach((product, pindex: number) => {
        defaultValues['product_'+pindex.toString()+'_template_id'] = product.template_id;
        defaultValues['product_'+pindex.toString()+'_is_csv'] = product.is_csv;

        if (product.headers && product.headers.length > 0) {
          product.headers.forEach((header: any, index: number) => {
            defaultValues['header_'+pindex.toString()+'_'+index.toString()+'_val'] = header.value;
            defaultValues['header_'+pindex.toString()+'_'+index.toString()+'_label'] = header.label;
          });
        }
    });
  }
  const form = useForm<FilePayload>({
    defaultValues: defaultValues,
  });

  
  const [newLender, setNewLender] = useState(0);

  const fetchTemplate =  useCallback(async (lenderId:string|number) => {
    return await apiClient.get(`lenders/${lenderId}/templates`);
  }, [newLender]);


  const updateLoanProduct = useUpdateLoanProduct(loanProduct.id, form);

  return (
    <CrupdateLoanProductLayout
      form={form}
      onSubmit={values => {
        updateLoanProduct.mutate(values);
      }}
      title={
        <Trans message="Edit :name loan product" values={{name:  loanProduct.name}} />
      }
      isLoading={updateLoanProduct.isPending}
    >
      <CrupdateLoanProductForm products={loanProduct.products} fetchTemplate={fetchTemplate}/>
    </CrupdateLoanProductLayout>
  );
}
