import {useMutation, useQueryClient} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {message} from '@common/i18n/message';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { LoanProduct } from '@app_modules/loan-products/loan-product';
import { useNavigate } from '@common/utils/hooks/use-navigate';

interface Response extends BackendResponse {
  loanProduct: LoanProduct;
}


export interface FilePayload {
  items: Array<any>;
  name: string;
  lender_id: string|number;
}

export function useCreateLoanProduct(form: UseFormReturn<FilePayload>) {
  const {trans} = useTrans();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: FilePayload) => createLoanProduct(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['loan-products']});
      toast(trans(message('Loan Product created')));
      navigate('/loan-products');
    },
    onError: err => onFormQueryError(err, form),
  });
}

async function createLoanProduct(payload: FilePayload) {
    return apiClient.post<Response>('loan-products', FilePayloadToFormData(payload), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(r => r.data);
}


export function FilePayloadToFormData(
  payload: Partial<FilePayload>,
) {
  const formData = new FormData();
  if (payload.name) {
    formData.append("name", payload.name);
  }
  if (payload.lender_id) {
    formData.append("lender_id", payload.lender_id.toString());
  }
  if (payload.items) {
    payload.items.forEach((product, index: number) => {
      formData.append("template_ids[]", product.template_id);
      formData.append(index+"_is_csv", product.is_csv);
          
      if (product.headers && product.headers.length > 0) {
        product.headers.forEach((header: any) => {
          formData.append(index+"_headers[]", header.label+":"+header.value);
        });
      }
      if (product.files && product.files.length > 0) {
        product.files.forEach((file: any) => {
          formData.append(index+"_files[]", file);
        });
      }
    });
  }
  
  return formData;
}
