import {useMutation, useQueryClient} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {apiClient} from '@common/http/query-client';
import {toast} from '@common/ui/toast/toast';
import {useTrans} from '@common/i18n/use-trans';
import {onFormQueryError} from '@common/errors/on-form-query-error';
import {message} from '@common/i18n/message';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { DatatableDataQueryKey } from '@common/datatable/requests/paginated-resources';
import { CheckPoint } from '@app_modules/check-points/check-point';
import { useNavigate } from '@common/utils/hooks/use-navigate';

interface Response extends BackendResponse {
  checkPoint: CheckPoint;
}


export interface CheckListPayload {
  name: string;
  lender_ids: string;
  items: Array<any>;
}

export function useCreateCheckList(form: UseFormReturn<CheckListPayload>) {
  const {trans} = useTrans();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: CheckListPayload) => createCheckList(payload),
    onSuccess: async () => {
      //await queryClient.invalidateQueries({queryKey: DatatableDataQueryKey('check-points')});
      // queryClient.invalidateQueries({queryKey: ['users']});
      toast(trans(message('Check List created')));
      navigate('/check-points');
    },
    onError: err => onFormQueryError(err, form),
  });
}

async function createCheckList(payload: CheckListPayload) {
    return apiClient.post<Response>('check-points', CheckListPayloadToFormData(payload)).then(r => r.data);
}



export function CheckListPayloadToFormData(
  payload: Partial<CheckListPayload>,
) {
  const formData = new FormData();
  if (payload.name) {
    formData.append("name", payload.name);
  }

  if (payload.lender_ids != undefined) {
    formData.append("lender_ids", payload.lender_ids);
  }
  
  if (payload.items) {
    payload.items.forEach((check_list, index: number) => {

      formData.append("keys[]", index.toString());
      if (check_list.subItems && check_list.subItems.length > 0) {
        check_list.subItems.forEach((subItem: any) => {
          formData.append(index+"_check_lists[]", JSON.stringify((subItem)));
        });
      }
    });
  }
  
  return formData;
}
