import {List, ListItem} from '@common/ui/list/list';
import {ReactNode, useContext, useState, useCallback, useEffect} from 'react';
import { Report } from './report';
import { Document } from '@app_modules/documents/document';
import moment from 'moment-timezone';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { IconButton } from '@common/ui/buttons/icon-button';
import { VisibilityIcon } from '@common/icons/material/Visibility';
import { FilePreviewDialog } from '@common/uploads/preview/file-preview-dialog';
import { useFileEntryModel } from '@common/uploads/requests/use-file-entry-model';


interface DocumentReportDocumentsSideLeftProps {
  report: Report;
  selectDocument: any,
  firstDocumentId: any,
}

export function DocumentReportDocumentsSideLeft({
  report,
  selectDocument,
  firstDocumentId,
}: DocumentReportDocumentsSideLeftProps) {
  const [documentId, setDocumentId] = useState();

  const [documents, setDocuments] = useState<Document[]>([]);
  
  const selectedDocument = (document: any) => {
    selectDocument(document);
    setDocumentId(document.id);
  }
  return (
    <aside className="sticky top-10 flex-shrink-0 lg:block border-r-2">
      <List padding="p-0" className='scroll-p-8 overflow-y-scroll h-800 min-h-800 bg-gray-300'>
        {report.documents.map((document,i) => (
          <Item 
            key={document.id}
            panel={document}
            isSelected={documentId==document.id || firstDocumentId == document.id}
            selectDocument={selectedDocument}
            
          >
            <div>
            <div className='h-48 rounded-panel border shadow py-4 px-2'>
              <span className={documentId==document.id || firstDocumentId == document.id ? 'inline-block align-middle text-[#3B81F6]' : 'inline-block align-middle'}>
              #{i+1}. <b>{document.original_filename}</b>
              </span>
              <span>
                <ViewOriginal file_id={document.file_id} />
              </span>
            </div>
            </div>
          </Item>
        ))}
        
      </List>
    </aside>
  );
}

interface ItemProps {
  children: ReactNode;
  isSelected?: boolean;
  panel: Document;
  selectDocument: any
}
function Item({children, isSelected, panel, selectDocument}: ItemProps) {
  return (
    <ListItem
      className={isSelected ?'bg-[#F4F8FE]' : undefined}
      padding="px-4 py-10"
      onSelected={() => {
        selectDocument(panel);
      }}
    >
      {children}
    </ListItem>
  );
}


interface ViewOriginalProps {
  file_id: number | string;
}

export function ViewOriginal({file_id}: ViewOriginalProps) {

  const {data} = useFileEntryModel (file_id);
  return (
    <DialogTrigger type="modal">
      <IconButton size="md" className="text-muted">
        <VisibilityIcon />
      </IconButton>
      <FilePreviewDialog entries={[data?.fileEntry!]} />
    </DialogTrigger>
  )
}