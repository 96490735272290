import {useTrans} from '@common/i18n/use-trans';
import {Fragment} from 'react';
import { Trans } from '@common/i18n/trans';
import { Button } from '@common/ui/buttons/button';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { Dialog } from '@common/ui/overlays/dialog/dialog';
import { DialogHeader } from '@common/ui/overlays/dialog/dialog-header';
import { DialogBody } from '@common/ui/overlays/dialog/dialog-body';
import { DialogFooter } from '@common/ui/overlays/dialog/dialog-footer';
import { List, ListItem } from '@common/ui/list/list';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { TextField } from '@common/ui/forms/input-field/text-field/text-field';
import "react-dropzone/examples/theme.css";

export function PromptAction({
  prompt,
}: {prompt: string}) {
  if (prompt) {
    return (
      <div className='flex w-full gap-10'>
      <DialogTrigger type="modal">
        <Button size={'xs'} variant="outline" color="orange">
          <Trans message="View Prompt" />
        </Button>
        <ViewPromptDialog prompt={prompt}/>
      </DialogTrigger>
        
      </div>
    );
  } else {
    return (<></>);
  }
}


export function ViewPromptDialog({prompt}: {prompt: string}) {
  
  const {trans} = useTrans();
  const {close} = useDialogContext();
  return (
    <Dialog size='2xl'>
      <DialogHeader>
        <Trans message={`View Prompt`} />
      </DialogHeader>
      <DialogBody>
        <Fragment>
          <List padding="p-0" className='scroll-p-8 overflow-y-scroll h-300 min-h-300 bg-gray-300'>
              <div key={`prompt-uw`}
              >
                <TextField
                className='form-input'
                name='message'
                value={prompt ? prompt : ''}
                inputElementType="textarea"
                rows={28}
                onChange={e => {
                  
                }}
              />
              </div>
          </List>
        </Fragment>
      </DialogBody>
      <DialogFooter>
      <Button onClick={() => close()}>
          <Trans message="Close" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}