import React, {Fragment} from 'react';
import {DataTablePage} from '@common/datatable/page/data-table-page';
import {IconButton} from '@common/ui/buttons/icon-button';
import {EditIcon} from '@common/icons/material/Edit';
import {ColumnConfig} from '@common/datatable/column-config';
import {Trans} from '@common/i18n/trans';
import {DataTableEmptyStateMessage} from '@common/datatable/page/data-table-emty-state-message';
import softwareEngineerSvg from '@common/admin/tags/software-engineer.svg';
import {DataTableAddItemButton} from '@common/datatable/data-table-add-item-button';
import {LoanProduct} from './loan-product';
import {Tooltip} from '@common/ui/tooltip/tooltip';
import {DeleteIcon} from '@common/icons/material/Delete';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {LoanProductsIndexPageFilters} from './loan-products-index-page-filters';
import moment from 'moment-timezone';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { CreateLoanProductDialog } from './create-loan-product-dialog';
import { UpdateLoanProductDialog } from './update-loan-product-dialog';
import { useDeleteLoanProduct } from '../admin/loan-products/requests/use-delete-loan-product';
import { Link } from 'react-router-dom';

export function LoanProductsIndexPage() {
  const navigate = useNavigate();
  const timezone = useUserTimezone();
  
  const columnConfig: ColumnConfig<LoanProduct>[] = [
    {
      key: 'id',
      allowsSorting: false,
      maxWidth: 'max-w-50',
      header: () => <Trans message="ID #" />,
      body:  loanProduct => loanProduct.id,
    },
    {
      key: 'lender',
      allowsSorting: false,
      maxWidth: 'max-w-120',
      header: () => <Trans message="Lender" />,
      body: loanProduct => loanProduct.lender_name,
    },
    
    {
      key: 'name',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Product Name" />,
      body:  loanProduct => loanProduct.name,
    },
    {
      key: 'status',
      allowsSorting: false,
      visibleInMode: 'all',
      header: () => <Trans message="Status" />,
      body:  loanProduct => {
        return (
          <Fragment>
            {loanProduct.status}
            {loanProduct.error ? (
              <span className='text-red'>
                <br/>{loanProduct.error}
              </span>
            ):(<></>)}
            
          </Fragment>
        );
        
      },
    },
    {
      key: 'updated_by',
      allowsSorting: false,
      header: () => <Trans message="Modified By" />,
      body: lender => lender.updated_by
    },
    {
      key: 'updated_at',
      allowsSorting: false,
      maxWidth: 'max-w-140',
      header: () => <Trans message="Last Modify Date" />,
      body: lender => lender.updated_at ? moment.utc(lender.updated_at).tz(timezone).format('MM/DD/YYYY HH:mm:ss') :''
      
    },
    {
      key: 'actions',
      header: () => <Trans message="Actions" />,
      visibleInMode: 'all',
      hideHeader: true,
      align: 'end',
      maxWidth: 'max-w-130',
      body:  loanProduct => {
        return (
          <Fragment>

          <IconButton
            size="md"
            className="text-muted"
            elementType={Link}
            to={`/loan-products/${ loanProduct.id}/edit`}
          >
            <EditIcon />
          </IconButton>

            {/* <DialogTrigger type="modal">
              <Tooltip label={<Trans message="Edit template" />}>
                <IconButton className="text-muted">
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <UpdateLoanProductDialog loanProduct={loanProduct} />
            </DialogTrigger> */}

            
            <DeleteLoanProductButton  loanProduct={ loanProduct} />
          </Fragment>
        );
        
      },
    },
  ];

  return (
    <DataTablePage
      endpoint="loan-products"
      title={<Trans message="Loan Products" />}
      columns={columnConfig}
      actions={<Actions />}
      enableSelection={false}
      filters={LoanProductsIndexPageFilters}
      // onRowAction={item => {
      //   navigate(`/reports/${item.report_id}/view`);
      // }}
      emptyStateMessage={
        <DataTableEmptyStateMessage
          image={softwareEngineerSvg}
          title={<Trans message="No Loan Products have been created yet" />}
          filteringTitle={<Trans message="No matching Loan Products" />}
        />
      }
    />
  );
}

interface DeleteLoanProductButtonProps {
  loanProduct: LoanProduct;
}
function DeleteLoanProductButton({ loanProduct}: DeleteLoanProductButtonProps) {
  const deleteLoanProduct = useDeleteLoanProduct();
  return (
    <DialogTrigger
      type="modal"
      onClose={confirmed => {
        if (confirmed) {
          deleteLoanProduct.mutate({ id:  loanProduct.id});
        }
      }}
    >
      <Tooltip label={<Trans message="Delete Loan Product" />}>
        <IconButton
          size="md"
          className="text-muted"
          disabled={deleteLoanProduct.isPending}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title={<Trans message="Delete Loan Product" />}
        body={<Trans message="Are you sure you want to delete this Loan Product?" />}
        confirm={<Trans message="Delete" />}
      />
    </DialogTrigger>
  );
}


function Actions() {
  return (
    // <DialogTrigger type="modal">
    //   <DataTableAddItemButton>
    //     <Trans message="New Loan Product" />
    //   </DataTableAddItemButton>
    //   <CreateLoanProductDialog />
    // </DialogTrigger>
    <Fragment>
      <DataTableAddItemButton elementType={Link} to="/loan-products/new">
        <Trans message="New Loan Product" />
      </DataTableAddItemButton>
    </Fragment>
  );
}