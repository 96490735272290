import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';
import {useParams} from 'react-router-dom';
import {Lender} from '../../../lenders/lender';

const Endpoint = (id: number | string) => `lenders/${id}`;

export interface FetchRoleResponse extends BackendResponse {
   lender: Lender;
}

export function useLender() {
  const { lenderId} = useParams();
  return useQuery({
    queryKey: [Endpoint( lenderId!)],
    queryFn: () => fetchLender( lenderId!),
  });
}

function fetchLender( lenderId: number | string): Promise<FetchRoleResponse> {
  return apiClient.get(Endpoint( lenderId)).then(response => response.data);
}
