import {useForm} from 'react-hook-form';
import {CrupdateResourceLayout} from '@common/admin/crupdate-resource-layout';
import {Trans} from '@common/i18n/trans';
import {CrupdateLenderForm} from './crupdate-lender-form';
import {
  useCreateLender,
} from '../requests/use-create-lender';
import { Lender } from '@app_modules/lenders/lender';
import { CrupdateLenderLayout } from './crupdate-lender-layout';

interface CreateLenderProps {
  callback_url: string;
}

export function CreateLenderPage({callback_url}: CreateLenderProps) {
  const form = useForm<Lender>({
    defaultValues: {
    },
  });
  const createLender = useCreateLender(form, callback_url);

  return (
    <CrupdateLenderLayout
      form={form}
      onSubmit={values => {
        createLender.mutate(values);
      }}
      title={<Trans message="Create new lender" />}
      isLoading={createLender.isPending}
    >
      <CrupdateLenderForm />
    </CrupdateLenderLayout>
  );
}
