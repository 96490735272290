import {useMutation} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {message} from '@common/i18n/message';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';
import { DatatableDataQueryKey } from '@common/datatable/requests/paginated-resources';
import { FileItem } from '@app_modules/loan-applications/step1/file-item';

interface Response extends BackendResponse {}

export interface CheckListPayload {
  
  fileItems: Array<FileItem>;
}

interface Payload {
  id: number|string;
  loan_category: string;
  document_type: string;
  files: any;
}

export function useUWCheckPointAction(id: number|string, loan_category: string, document_type: string, files: any) {
  return useMutation({
    mutationFn: ({id, loan_category, document_type}: Payload) =>
      executeUWAction({id: id, loan_category: loan_category, document_type: document_type, files: files}),
      onSuccess: (response: any, payload) => {
      if (response.message) {
        toast(
          message(response.message),
        );
      } else {
        toast(
          message(`You has just executed check ${document_type}.`),
        );
      }
      
      queryClient.invalidateQueries({queryKey: DatatableDataQueryKey(`check-points/${id}`)});
    },
    onError: err => showHttpErrorToast(err),
  });
}

function executeUWAction({
  id, loan_category, document_type, files
}: Payload): Promise<Response> {
  return apiClient
    .post(`check-points/${id}/execute-action`, FilePayloadToFormData({loan_category: loan_category, document_type: document_type, files: files}), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data);
}

export function FilePayloadToFormData(
payload: Partial<Payload>,
) {
  const formData = new FormData();
  if (payload.document_type) {
    formData.append("document_type", payload.document_type);
  }
  if (payload.loan_category) {
    formData.append("loan_category", payload.loan_category);
  }
  if (payload.files) {
      if (payload.files && payload.files.length > 0) {
        payload.files.forEach((file: any) => {
          formData.append("files[]", file);
        });
      }
  }

  return formData;
}

