import {useLoanProducts} from '../use-loan-products';
import {useAuth} from '@common/auth/use-auth';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import {useTrans} from '@common/i18n/use-trans';
import {message} from '@common/i18n/message';
import {Footer} from '@common/ui/footer/footer';
import {AnimatePresence, m} from 'framer-motion';
import {DashboardNavbar} from '@app/dashboard/dashboard-navbar';
import {useAccountUsage} from '@app/editor/use-account-usage';
import {AdHost} from '@common/admin/ads/ad-host';
import {StaticPageTitle} from '@common/seo/static-page-title';
import { LoanProductsIndexPage } from './loan-products-index-page';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import { LoanProductViewPage } from './loan-product-view-page';
import { EditLoanProductPage } from './edit-loan-product-page';
import { CreateLoanProductPage } from './create-loan-product-page';

const SortOptions = [
  {value: 'created_at|desc', label: message('Request Date')},
  {value: 'first_name|asc', label: message('Alphabetical')},
  {value: 'updated_at|desc', label: message('Last updated')},
];

export function LoanProductPage() {
  const {data: usage} = useAccountUsage();
  const navigate = useNavigate();
  const {trans} = useTrans();
  const {user} = useAuth();
  const userId = user!.id;
  const query = useLoanProducts({userId: user!.id, paginate: 'lengthAware'});
  const {sortDescriptor, setSortDescriptor, searchQuery, setSearchQuery} =
    query;
  const sortValue = `${sortDescriptor.orderBy}|${sortDescriptor.orderDir}`;

  // useEffect(() => {
  //   window.Echo.channel('report').listen('.new', (e: any) => {
  //     if (e.data[userId] != undefined) {
  //       const item = e.data[userId];
  //       if (item.report_id) {
  //         navigate(`/reports/${item.report_id}/view`);
  //       }
  //     }
  //   });
  // }, [])

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="Loan Products" />
      </StaticPageTitle>
      <div className="flex h-screen flex-col overflow-y-scroll">
        <DashboardNavbar />
        <div className="relative">
          <div className=" mx-auto flex-auto px-12">
            <div className="items-center gap-12 py-361">
              <div className='mr-auto'>
              <AdHost slot="dashboard_top" className="mb-36" />
              <AnimatePresence initial={false} mode="wait">
                <LoanProductsIndexPage/>
              
              </AnimatePresence>
              <AdHost slot="dashboard_bottom" className="mt-36" />
              </div>
            </div>
          </div>
        </div>
        
        <Footer className="mt-40 px-40" />
      </div>
    </Fragment>
  );
}

export function ViewLoanProductPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="Loan Product Detail" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="relative">
        <div className=" mx-auto mt-40 px-20">
          <LoanProductViewPage callback_url="/loan-products"/>
        </div>
      </div>
      <Footer className="mt-40 px-40" />
    </div>
  );
}

export function NewLoanProductPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="New Loan Product" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="relative">
        <div className=" mx-auto mt-40 px-20">
          <CreateLoanProductPage/>
        </div>
      </div>
      <Footer className="mt-40 px-40" />

    </div>
  );
}


export function UpdateLoanProductPage() {
  return (
    <div>
      <StaticPageTitle>
        <Trans message="Edit LoanProduct" />
      </StaticPageTitle>
      <DashboardNavbar />
      <div className="relative">
        <div className=" mx-auto mt-40 px-20">
          <EditLoanProductPage/>
        </div>
      </div>
      <Footer className="mt-40 px-40" />
    </div>
  );
}




