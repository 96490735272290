import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode} from 'react';
import {FormSelect} from '@common/ui/forms/select/select';
import {Item} from '@common/ui/forms/listbox/item';
import { FormDatePicker } from '@common/ui/forms/input-field/date/date-picker/date-picker';
import { useSettings } from '@common/core/settings/use-settings';

export function CrupdateLenderForm() {
  const {report: setting_report} = useSettings();
  const status_lists = setting_report ? JSON.parse(setting_report.status_lists):[];

  return (
    <Fragment>
      <FormTextField
        name="name"
        label={<Trans message="Name" />}
        className="mb-20"
        required
        autoFocus
      />
      
    </Fragment>
  );
}

interface HeaderProps {
  children: ReactNode;
}
function Header({children}: HeaderProps) {
  return <h2 className="mt-40 mb-20 text-base font-semibold">{children}</h2>;
}


