import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {Trans} from '@common/i18n/trans';
import {useFormContext} from 'react-hook-form';
import { FileWithPath, useDropzone } from 'react-dropzone';
import "react-dropzone/examples/theme.css";
import { FormSelect } from '@common/ui/forms/select/select';
import { Lender } from '@app_modules/lenders/lender';
import { Item } from '@common/ui/forms/listbox/item';
import { FilePayload } from '@app_modules/requests/use-create-loan-product';
import { ProductTemplate } from '@app_modules/product-templates/product-template';
import { LoanProduct } from '@app_modules/loan-products/loan-product';
import { useMyLenders } from '@app_modules/admin/lenders/requests/use-my-lenders';
import { MetaItem } from '@app_modules/loan-products/meta-item';
import { Button } from '@common/ui/buttons/button';
import { RemoveIcon } from '@common/icons/material/Remove';
import { AddIcon } from '@common/icons/material/Add';
import { List, ListItem } from '@common/ui/list/list';
import { ProductItem } from '@app_modules/loan-products/product-item';
import { ViewOriginal } from '@app_modules/document-requests/reports/document-report-documents-sideleft';
import { CheckCircleOutlineIcon } from '@common/icons/material/CheckCircleOutline';
import { CloseIcon } from '@common/icons/material/Close';
import { queryClient } from '@common/http/query-client';
import { FormSwitch } from '@common/ui/forms/toggle/switch';
import { useDownloadMdProduct } from '@app_modules/requests/use-download-md';
import { downloadFile } from '@app_modules/document-requests/reports/requests/use-download-bsa-report';
import { DownloadIcon } from '@common/icons/material/Download';
import { formatBytes } from '@common/utils/number/byte-format';

export function CrupdateLoanProductForm({products, fetchTemplate, isEnable}:{products?: any, fetchTemplate: any, isEnable?: any}) {

  const {watch} = useFormContext<LoanProduct>();
  const lender_id = watch('lender_id');


  const {data: mylenders} = useMyLenders();

  const lenders = mylenders?.lenders;

  const form = useFormContext<FilePayload>();

  const [items, setItems] = useState<ProductItem[]>(products ? products : [{key: "1"}]);
  const len = products ? products.length : 1;
  const [cnt, setCnt] = useState(len);
  const [last, setLast] = useState(len);

  useEffect(() => {
    setItems(items);
    setLast(last+1);
  }, [cnt]);

  useEffect(() => {
    window.Echo.channel('embed').listen('.completed', (e: any) => {
      if (e.data.id) {
        const productId = e.data.id;
        queryClient.invalidateQueries({queryKey: [`loan-products/${productId}`]});
      }
    });
  });

  const [headers, setHeaders] = useState([]);
  const [files, setFiles] = useState([]);
  
  const onHeaders = useCallback((pidx: number, itemHeaders: any) => {
    
    items[pidx]['headers'] = itemHeaders;
    setHeaders(itemHeaders);
    form.setValue('items', items);
	}, []);

  const onFiles = useCallback((pidx: number, itemFiles: any) => {
    items[pidx]['files'] = itemFiles;
    setFiles(itemFiles);
    form.setValue('items', items);
	}, []);

  useEffect(() => {
    setItems(items);
    form.setValue('items', items);
  }, [form, headers, files]);


  return (
    <Fragment>
      <FormTextField
        autoFocus
        name="name"
        label={<Trans message="Product Name" />}
        className="mb-20"
        required
      />

      <FormSelect
        name="lender_id"
        selectionMode="single"
        required
        label={<Trans message="Lender" />}
        className="mb-20"   
      >
        {lenders?.map((lender: Lender) => (
        <Item value={lender.id} key={lender.id}>
          <Trans message={lender.name} />
        </Item>
        ))}
      </FormSelect>
        
      <div className='gap-10 '>
        <div className='flex'>
          <h3 className='w-[90%] font-bold'>Loan Product Files</h3>
          <div className='w-[10%] p-10'>
          <Button
            startIcon={<AddIcon />}
            size={'xs'} variant="flat" color="green"
            onClick={() => {
              items.push({key: last.toString(), 'headers': [{key: "1", label: "", value: ""}]});
              setCnt(cnt+1);
            }}
            >
            <Trans message="Add" />
          </Button>
          </div>
        </div>
        <aside className="sticky top-10 w-[100%] flex">
          <List padding="p-0" className='scroll-p-8 overflow-y-scroll h-400 min-h-400 w-[900%]'>
          {items?.map((item: any, index: number) => (
            <ListItem
              className='border-b-1'
            >
              <>
                <ProductLoanItem onHeaders={onHeaders} onFiles={onFiles} key={`item-${index}`} pidx={index} item={item} isEnable={isEnable}/>
                <div className='float-right'>
                {cnt>1?(
                    <Button
                      startIcon={<RemoveIcon />}
                      size={'xs'} variant="outline" color="red"
                      onClick={() => {
                        items.splice(index, 1);
                        setCnt(cnt-1);
                        form.setValue('items', items);
                      }}
                      >
                      
                      <Trans message="Remove" />
                    </Button>
                  ): (<>
                  <div className='w-[100px]'></div>
                  </>)
                }
                </div>
                </>
              </ListItem>
              ))}
          </List>
        </aside>
      </div>

    </Fragment>
  );
}

function ProductLoanItem({onHeaders, onFiles, pidx, item, isEnable}: {onHeaders: any, onFiles: any, pidx: number, item: any, isEnable: any}) {
  const [files, setFiles] = useState([]);

  
  const onDrop = useCallback((acceptedFiles: any) => {

    setFiles(acceptedFiles);
    if (isEnable) {
      isEnable(true);
    }

	}, []);

  const [metaItems, setMetaItems] = useState<MetaItem[]>(item.headers? item.headers : [{key: "1", label: "", value: ""}]);

  const len = item.headers ? item.headers.length : 1;
  
  const [cnt, setCnt] = useState(len);
  const [last, setLast] = useState(len);


  useEffect(() => {
    onFiles(pidx, files);
  }, [files]);

  useEffect(() => {
    setMetaItems(metaItems);
    setLast(last+1);
  }, [cnt]);

  useEffect(() => {
    setMetaItems(metaItems);
  }, [metaItems]);

  return (
    <>
      <div className='gap-10 flex w-[100%]'>
        <div className='w-[50%]'>
          <div className='gap-10'>
            <div className='flex'>
              <h3 className='w-[80%]'>Header Fields</h3>
              <div className='w-[20%] '>
              <Button
                startIcon={<AddIcon />}
                size={'xs'} variant="outline" color="green"
                onClick={() => {
                  metaItems.push({key: last.toString(), value: "", label: ""});
                  setCnt(cnt+1);
                  
                  onHeaders(pidx, metaItems);
                }}
                >
                
                <Trans message="Add" />
              </Button>
              </div>
            </div>
            <div className='w-[100%] flex gap-10'>
              <div className="table-wrp block flex flex-col overflow-y-scroll ml-20">
                <table className="w-[100%] table">
                  <thead className="border-b sticky top-0 bg-slate-300">
                    <tr className='bg-gray-300'>
                      <th scope="col" className="bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
                      <Trans message="Header Key" />
                      </th>
                      <th scope="col" className="bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
                      <Trans message="Header Label" />
                      </th>
                      <th scope="col" className="bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
                      </th>
                    </tr>
                  </thead>
                  <tbody className="h-30 overflow-y-auto">
                  {metaItems?.map((metaItem: MetaItem, index: number) => (
                    <tr key={`meta-${item.key}-${metaItem.key}`} className="border-b transition duration-300 ease-in-out hover:bg-gray-100 w-[100%]">
                      <td className={`text-sm text-gray-900 px-6 py-4 whitespace-nowrap border-r  border-l`}>
                        <FormTextField
                          className="w-[100%]"
                          name={`header_${item.key}_${metaItem.key}_label`}
                          onChange={e => {
                            metaItems[index]['label'] = e.target.value;
                            
                            onHeaders(pidx, metaItems);
                          }}
                        />
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                        <FormTextField
                          className="w-[100%]"
                          name={`header_${item.key}_${metaItem.key}_val`}
                          onChange={e => {
                            metaItems[index]['value'] = e.target.value;
                            onHeaders(pidx, metaItems);
                          }}
                        />
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                        {metaItems.length>1?(
                          <Button
                            startIcon={<RemoveIcon />}
                            size={'xs'} variant="outline" color="red"
                            onClick={() => {
                              if (metaItems.length > 1) {
                                metaItems.splice(index, 1);
                              }
                              if (cnt > 1) {
                                setCnt(cnt-1);
                              } else {
                                setCnt(1);
                              }
                              onHeaders(pidx, metaItems);
                            }}
                            >
                            <Trans message="Remove" />
                          </Button>
                        ): (<>
                        <div className='w-[100px]'></div>
                        </>)
                      }
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[50%]'>
          <TemplateFile onDrop={onDrop} itemFiles={item.item_files}/>
        </div>
    
      </div>
    </>
  );
}

function TemplateFile({onDrop, itemFiles}: {onDrop: any, itemFiles: any}) {

  const max_file_upload = 1;

  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'application/pdf': []
    },
    onDrop,
    maxFiles: max_file_upload,
    multiple: false
  });

  const downloadProduct = useDownloadMdProduct();

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone h-100' })}>
        
        <input name='files' {...getInputProps()} />

        <p>Only 1 pdf will be accepted  can drop here</p>
      </div >
        <div>
        <h4 className='h-40 t-40'><strong>Uploaded file</strong></h4>
        <div className="table-wrp block flex flex-col overflow-y-scroll">
          <table className="w-full file">
            <thead className="bg-white border-b sticky top-0 bg-slate-300">
              <tr className='bg-gray-300'>
              <th scope="col" className="bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 pl-20 text-left">
                  File Name
                </th>
                <th scope="col" className="bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
                  Size
                </th>
                {acceptedFiles.length == 0 && itemFiles ? (<>
                <th scope="col" className="bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left">
                  Embed?
                </th>
                <th></th>
                </>) : (<></>)}
              </tr>
            </thead>
            <tbody className="h-96 overflow-y-auto">
            {acceptedFiles.map((file: FileWithPath, index) => (
              <tr key={file.path} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td className="text-sm font-extrabold text-gray-900 font-light px-6 py-4 pl-20 whitespace-nowrap">
                {index+1}. {file.path}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{formatBytes(file.size)}</td>
              </tr>
              ))}
            {acceptedFiles.length == 0 && itemFiles && itemFiles.map((file: any, index: number) => (
              <tr key={file.id} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td className="text-sm font-extrabold text-gray-900 font-light px-6 py-4 pl-20 whitespace-nowrap">
                {index+1}. {file.name}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{formatBytes(file.size)}</td>
                <td>
                  <span>
                    {file.status}
                  </span>
                </td>
                <td>
                  <span>
                  <ViewOriginal file_id={file.id} />

                  {file.md_file_name ? (
                  <Button
                    variant="outline"
                    color="primary"
                    size={'2xs'}
                    onClick={() => {
                      downloadProduct.mutate({loan_product_id: file.loan_product_id, file_name: file.md_file_name}, {
                        onSuccess: (response) => {
                          downloadFile(file.md_file_name, response)
                        },
                      });
                    }}
                  >
                    <DownloadIcon/>
                  </Button>) : (<></>)}
                </span>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}


